import useTranslation from '@/utils/i18n/useTranslation';
import {
  ChakraProps,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { FC, FormEvent } from 'react';
import { MdClose as CloseIcon, MdSearch as SearchIcon } from 'react-icons/md';

type SearchBarProps = {
  placeholder?: string;
  value: string;
  sx?: ChakraProps['sx'];
  setValue: (value: string) => void;
  onSearch: (searchText: string) => void;
};

const SearchBar: FC<SearchBarProps> = (props: SearchBarProps) => {
  const { t } = useTranslation();

  const { value, sx, setValue, onSearch, placeholder = t('actions.search') } = props;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSearch(value);
  };

  const handleClear = () => {
    setValue('');
    onSearch('');
  };

  return (
    <HStack sx={sx}>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <InputGroup
          size='sm'
          borderColor={{
            base: 'transparent',
            md: 'neutral.300',
          }}
        >
          <InputLeftElement pointerEvents='none'>
            <SearchIcon color='neutral.500' />
          </InputLeftElement>
          <Input
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            placeholder={placeholder}
            _placeholder={{ color: 'neutral.500' }}
            rounded='md'
            sx={{
              borderColor: 'neutral.300',
            }}
            minW={{ base: 'auto', md: '200px' }}
          />
          <InputRightElement
            justifyContent='end'
            pointerEvents={value.length > 0 ? 'auto' : 'none'}
          >
            {value.length > 0 && (
              <IconButton
                as='div'
                fontSize={16}
                variant='unstyled'
                display='flex'
                justifyContent='center'
                alignItems='center'
                aria-label='Clear'
                color='neutral.500'
                size='sm'
                cursor='pointer'
                icon={<CloseIcon />}
                onClick={() => handleClear()}
              />
            )}
          </InputRightElement>
        </InputGroup>
      </form>
    </HStack>
  );
};

export default SearchBar;
